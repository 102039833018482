import React from "react";
import { Col, Row } from "react-bootstrap";
import LeftContent from "../Common/LeftContent";
import RightContent from "../Common/RightContent";
import Navigation from "../Common/Navigation";
import BottomContent from "../Common/BottomContent";
import Logo from "../Common/Logo";

const Awards: React.FC = () => {
  const renderAwardImage = (src: string, alt: string) => {
    return (
      <Col xs={12} sm={12} md={6}>
        <img className="award-image" src={src} alt={alt} />
      </Col>
    );
  };
  const renderText = (title: string, className?: string) => {
    return (
      <Row>
        <Col>
          <span className={className}>{title}</span>
        </Col>
      </Row>
    );
  };
  return (
    <div className="awards">
      <div className="desktop-content">
        <LeftContent />
        <div className="middle-content">
          <Row>
            <Col>
              <img src="../1.png" alt="Naslov" style={{ width: "80%" }} />
            </Col>
          </Row>
          <Row className="image-row">
            <Col xs={12} sm={12} md={6}>
              <img src="../2.png" alt="nagrada" />
            </Col>
            <Col xs={12} sm={12} md={6}>
              <img src="../3.png" alt="nagrada" />
            </Col>
          </Row>
          <Row className="image-row">
            <Col xs={12} sm={12} md={6}>
              <img src="../4.png" alt="nagrada" />
            </Col>
            <Col xs={12} sm={12} md={6}>
              <img src="../5.png" alt="nagrada" />
            </Col>
          </Row>
          <Row className="image-row">
            <Col xs={12} sm={12} md={6}>
              <img src="../6.png" alt="nagrada" />
            </Col>
            <Col xs={12} sm={12} md={6}>
              <img src="../7.png" alt="nagrada" />
            </Col>
          </Row>
          {/* <Row className="image-row">
            <Col xs={12} sm={12} md={12} className="centered-col">
              <img
                src="../8.png"
                alt="Naslov"
                // style={{ width: "88%" }}
              />
              <span className="text-white-big lh-1 fs-20">
                *FOTOGRAFIJE POKLONA SU ILUSTRATIVNOG KARAKTERA
              </span>
            </Col>
          </Row> */}
          <Row>
            <Col>
              <img src="../8.png" alt="Naslov" style={{ width: "40%" }} />
              {renderText(
                "*FOTOGRAFIJE POKLONA SU ILUSTRATIVNOG KARAKTERA",
                "text-white-big lh-0 fs-14"
              )}
            </Col>
          </Row>
          {/* <Row className="image-row">
            <Col xs={12} sm={12} md={12}>
              <img src="../vaucer-nagrade.png" alt="nagrada" />
              {renderText(
                "*FOTOGRAFIJE POKLONA SU ILUSTRATIVNOG KARAKTERA",
                "text-white-big lh-1 fs-20"
              )}
            </Col>
          </Row> */}
          {/* {renderText(
            "*FOTOGRAFIJE POKLONA SU ILUSTRATIVNOG KARAKTERA",
            "text-white-big lh-1 fs-20"
          )} */}
        </div>
        <RightContent />
      </div>
      <div className="mobile-content">
        <Logo />
        <Navigation />
        <div className="middle-content">
          <Row>{renderAwardImage("../1.png", "nagrada")}</Row>
          <Row>
            {renderAwardImage("../2.png", "nagrada")}
            {renderAwardImage("../3.png", "nagrada")}
          </Row>
          <Row>
            {renderAwardImage("../4.png", "nagrada")}
            {renderAwardImage("../5.png", "nagrada")}
          </Row>
          <Row>
            {renderAwardImage("../6.png", "nagrada")}
            {renderAwardImage("../7.png", "nagrada")}
          </Row>
          <Row>{renderAwardImage("../8.png", "nagrada")}</Row>

          {renderText(
            "*NAGRADE SU ILUSTRATIVNOG KARAKTERA",
            "text-white-big lh-1 fs-10"
          )}
        </div>
        <BottomContent />
      </div>
    </div>
  );
};

export default Awards;
