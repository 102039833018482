import moment from "moment";
import AfterEnd from "../Pages/AfterEnd";
import BeforeStart from "../Pages/BeforeStart";

const CommonContainer = (props: any) => {
  const gameStarted = () => {
    const now = moment();
    const start = moment("2024-07-04T00:00:00+02:00");
    if (now > start) {
      return true;
    }
    return false;
  };

  const gameEnded = () => {
    const now = moment();
    const end = moment("2024-08-22T23:59:59+02:00");
    if (now > end) {
      return true;
    }
    return false;
  };

  if (gameStarted()) {
    if (gameEnded()) {
      return <AfterEnd />;
    } else {
      return <>{props.children}</>;
    }
  } else {
    return <BeforeStart />;
  }
};

export default CommonContainer;
