import React, { useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import LeftContent from "../Common/LeftContent";
import RightContent from "../Common/RightContent";
import Navigation from "../Common/Navigation";
import BottomContent from "../Common/BottomContent";
import Logo from "../Common/Logo";
import PopUp from "../Common/PopUp";

const Home: React.FC = () => {
  const renderText = (title: string, className?: string) => {
    return (
      <Row>
        <Col>
          <span className={className}>{title}</span>
        </Col>
      </Row>
    );
  };

  const [open, setOpen] = useState(true);
  const [mtsMessage, setMtsMessage] = useState(
    "Obaveštavamo MTS korisnike o kraćem prekidu servisa, molimo vas da se javite 9. 8. 2023. call centru, ukoliko ste imali problema prilikom slanja koda."
  );

  return (
    <div className="home">
      <div className="desktop-content">
        <LeftContent />
        <div className="middle-content">
          {renderText("BOGI", "text-white-big")}
          {renderText("TE ZOVE U", "text-ultra-green-big lh-0")}
          {renderText("AMERIKU", "text-white-big lh-1")}
          <br />
          <Row>
            <Col lg={3} />
            <Col lg={6}>
              <Link to="/prijava">
                <Button className="sign-up">PRIJAVI SE</Button>
              </Link>
            </Col>
            <Col lg={3} />
          </Row>
          <br />
          {renderText(
            "PRIJAVE ZA NAGRADNU IGRU",
            "text-white-small lh-0 fw-500"
          )}
          {renderText(
            "TRAJU od 05.07. do 16.08. 2024. GODINE",
            "text-white-small lh-0 fw-500"
          )}
        </div>
        <RightContent />
      </div>
      <div className="mobile-content">
        <Logo />
        <Navigation />
        <div className="middle-content">
          <Row>
            <Col xs={3} />
            <Col xs={6}>
              <img
                className="home-bogdan-figure-1"
                src="../U0015BogdanovicFigure1v1.png"
                alt="Logo"
              />
            </Col>
            <Col xs={3} />
          </Row>
          {renderText("BOGI", "text-white-big fs-55")}
          {renderText("TE ZOVE U", "text-ultra-green-big fs-35 lh-0")}
          {renderText("AMERIKU", "text-white-big fs-55 lh-1")}
          <br />
          <Row>
            <Col xs={3} />
            <Col xs={6}>
              <Link to="/prijava">
                <Button className="sign-up fs-25">PRIJAVI SE</Button>
              </Link>
            </Col>
            <Col xs={3} />
          </Row>
          <br />
          {renderText(
            "PRIJAVE ZA NAGRADNU IGRU",
            "text-white-small fs-14 lh-0 fw-500"
          )}
          {renderText(
            "TRAJU od 05.07. do 16.08. 2024. GODINE",
            "text-white-small fs-14 lh-1 fw-500"
          )}
          <br />
        </div>
        <br />
        <BottomContent />

        {/* <PopUp open={open} setOpen={setOpen} message={mtsMessage} /> OVO JE POPUP ZA OBAVESTENJE STO JE BILO IZ AKTONA */}
      </div>
    </div>
  );
};

export default Home;
