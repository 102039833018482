import React from "react";
import { Nav, NavLink, Navbar } from "react-bootstrap";
import { Link } from "react-router-dom";
import Logo from "./Logo";

const Navigation: React.FC = () => {
    return (
        <Navbar collapseOnSelect={true} expand="lg" bg="" variant="">
            <Navbar.Toggle aria-controls="navbarScroll" data-bs-toggle="collapse" data-bs-target="#navbarScroll" />
            <Navbar.Collapse id="navbarScroll">
                <Nav>
                    <NavLink eventKey="1" as={Link} to="/prijava">PRIJAVI SE</NavLink>
                    <NavLink eventKey="2" as={Link} to="/mehanizam">MEHANIZAM</NavLink>
                    <NavLink eventKey="3" as={Link} to="/nagrade">NAGRADE</NavLink>
                    <NavLink eventKey="4" as={Link} to="/dobitnici">DOBITNICI</NavLink>
                    <NavLink eventKey="5" as={Link} to="/pravila">PRAVILA</NavLink>
                    <NavLink eventKey="6" as={Link} to="/kontakt">KONTAKT</NavLink>
                </Nav>
            </Navbar.Collapse>     
        </Navbar>
    );
}

export default Navigation;