import Logo from "./Logo";
import NavButtons from "./NavButtons";

const LeftContent = () => {
  return (
    <div className="left-content">
      <Logo />
      <NavButtons />
    </div>
  );
  
}

export default LeftContent;