import React from "react";
import { Col, Row } from "react-bootstrap";
import BottomContent from "../Common/BottomContent";
import LeftContent from "../Common/LeftContent";
import Logo from "../Common/Logo";
import Navigation from "../Common/Navigation";
import RightContent from "../Common/RightContent";

const Contact: React.FC = () => {
  const renderText = (title: string, className?: string, link?: string) => {
    const noUnderlineStyle = { textDecoration: "none" };

    if (link) {
      return (
        <Row>
          <Col>
            <a href={link} className={className} style={noUnderlineStyle}>
              {title}
            </a>
          </Col>
        </Row>
      );
    }
    return (
      <Row>
        <Col>
          <span className={className}>{title}</span>
        </Col>
      </Row>
    );
  };

  return (
    <div className="contact">
      <div className="desktop-content">
        <LeftContent />
        <div className="middle-content">
          {renderText("CALL CENTAR", "text-white-big")}
          <br />
          {renderText(
            "063 272391",
            "text-white-big lh-03 fs-60",
            "tel:+38163272391"
          )}
          <br />
          <Row>
            <Col lg={3} />
            <Col lg={6} className="ultra-bg p-20">
              {renderText("Radno vreme call centra je", "text-black-small")}
              {renderText("svakim radnim danom", "text-black-small")}
              {renderText("od 10h do 16h", "text-black-big")}
            </Col>
            <Col lg={3} />
          </Row>
          <br />
          {renderText("E-MAIL:", "text-white-small lh-0")}
          {renderText(
            "info@ultrachallenge.rs",
            "text-white-small lh-1",
            "mailto:info@ultrachallenge.rs"
          )}
          {/* {renderText("TELEFON:", "text-white-small lh-0")}
          {renderText("063 272391", "text-white-small lh-0")} */}
        </div>
        <RightContent />
      </div>
      <div className="mobile-content">
        <Logo />
        <Navigation />
        <div className="middle-content">
          {renderText("CALL CENTAR", "text-white-big fs-35")}
          <br />
          {renderText(
            "063 272391",
            "text-white-big lh-03 fs-50",
            "tel:+38163272391"
          )}
          <br />
          <Row>
            <Col lg={3} />
            <Col lg={6} className="ultra-bg p-15">
              {renderText("Radno vreme call centra je", "text-black-small")}
              {renderText("svakim radnim danom", "text-black-small")}
              {renderText("od 10h do 16h", "text-black-big")}
            </Col>
            <Col lg={3} />
          </Row>
          <br />
          {renderText("E-MAIL:", "text-white-small lh-0")}
          {renderText(
            "info@ultrachallenge.rs",
            "text-white-small lh-1",
            "mailto:info@ultrachallenge.rs"
          )}
          {/* {renderText("TELEFON:", "text-white-small lh-0")}
          {renderText("063 272391", "text-white-small lh-0")} */}
        </div>
        <BottomContent />
      </div>
    </div>
  );
};

export default Contact;
