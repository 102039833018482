import React from "react";
import { Col, Row } from "react-bootstrap";

const BottomContent: React.FC = () => {
    return (
        <Row className="page-bottom">
            <Col xs={12}>
                <img src="../doleMobile.png" alt="Logo" />
            </Col>
        </Row>
    );
};

export default BottomContent;