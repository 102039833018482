import React from "react";
import { Col, Row } from "react-bootstrap";
import BottomContent from "../Common/BottomContent";
import LeftContent from "../Common/LeftContent";
import Logo from "../Common/Logo";
import Navigation from "../Common/Navigation";
import RightContent from "../Common/RightContent";

const Mechanism: React.FC = () => {
  const renderText = (title: string, className?: string) => {
    return (
      <Row>
        <Col>
          <span className={className}>{title}</span>
        </Col>
      </Row>
    );
  };

  return (
    <div className="mechanism">
      <div className="desktop-content">
        <LeftContent />
        <div className="middle-content">
          {renderText("BOGI", "text-white-big")}
          {renderText("TE ZOVE U", "text-ultra-green-big lh-0")}
          {renderText("AMERIKU", "text-white-big lh-1")}
          <br />
          {renderText(
            "Kupi bilo koju Ultra Energy limenku od 0,25L ili 0,5L.",
            "text-white-small lh-0"
          )}
          <br />
          {renderText(
            "Pošalji kod koji se nalazi ispod zlatnog otvarača",
            "text-white-small lh-0"
          )}
          <Row>
            <Col>
              <span className="text-white-small lh-0">
                SMS-om na 6202 u formi: Ultra (razmak) kod ili na{" "}
              </span>
              <a href="/prijava" className="text-ultra-green-small fw-500 lh-0">
                ULTRACHALLENGE.RS
              </a>
            </Col>
          </Row>
          <br />
          <Row>
            <Col lg={2} />
            <Col lg={8} className="ultra-bg p-20">
              {renderText("Osvoji glavnu nagradu:", "text-white-small fw-500")}
              <br />
              {renderText("PUT U AMERIKU", "text-black-small fw-700")}
              {renderText(
                "ILI NEKU OD DNEVNIH NAGRADA.",
                "text-black-small fw-700"
              )}
            </Col>
            <Col lg={2} />
          </Row>
          <br />
          {renderText(
            "Svi dobitnici će biti kontaktirani.",
            "text-white-small lh-0"
          )}
        </div>
        <RightContent />
      </div>
      <div className="mobile-content">
        <Logo />
        <Navigation />
        <div className="middle-content">
          {renderText("BOGI", "text-white-big fs-55")}
          {renderText("TE ZOVE U", "text-ultra-green-big fs-30 lh-0")}
          {renderText("AMERIKU", "text-white-big fs-55 lh-1")}
          <br />
          {renderText(
            "Kupi bilo koju Ultra Energy limenku od 0,25L ili 0,5L.",
            "text-white-small lh-0"
          )}
          {renderText(
            "Pošalji kod koji se nalazi ispod zlatnog otvarača",
            "text-white-small lh-0"
          )}
          <Row>
            <Col>
              <span className="text-white-small lh-0">
                SMS-om na 6202 u formi: Ultra (razmak) kod ili na{" "}
              </span>
              <a href="/prijava" className="text-ultra-green-small fw-500 lh-0">
                ULTRACHALLENGE.RS
              </a>
            </Col>
          </Row>
          <br />
          <Row>
            <Col lg={2} />
            <Col lg={8} className="ultra-bg p-15">
              {renderText("Osvoji glavnu nagradu:", "text-white-small fw-500")}
              {renderText("PUT U AMERIKU", "text-black-small fw-700")}
              {renderText(
                "ILI NEKU OD DNEVNIH NAGRADA.",
                "text-black-small fw-700"
              )}
            </Col>
            <Col lg={2} />
          </Row>
          {renderText(
            "Svi dobitnici će biti kontaktirani.",
            "text-white-small lh-1"
          )}
        </div>
        <BottomContent />
      </div>
    </div>
  );
};

export default Mechanism;
