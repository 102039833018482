import React from "react";
import { Col, Row } from "react-bootstrap";
import BottomContent from "../Common/BottomContent";
import LeftContent from "../Common/LeftContent";
import Logo from "../Common/Logo";
import Navigation from "../Common/Navigation";
import RightContent from "../Common/RightContent";

const AfterEnd: React.FC = () => {
  const renderText = (title: string, className?: string) => {
    return (
      <Row>
        <Col>
          <span className={className}>{title}</span>
        </Col>
      </Row>
    );
  };

  return (
    <div className="afterEnd">
      <div className="desktop-content">
        {/* <LeftContent /> */}
        <img className="logo" src="../logo.png" alt="Ultra" />
        <div className="middle-content">
          {renderText("NAGRADNA", "text-white-big lh-1")}
          {renderText("IGRA JE", "text-white-big lh-1.5")}
          {renderText("ZAVRŠENA", "text-white-big lh-1")}
          <br />
          <Row>
            <Col lg={3} />
            <Col lg={6} className="ultra-bg p-20">
              {renderText("PRATITE NAS NA", "text-black-small")}
            </Col>
            <Col lg={3} />
          </Row>
          <br />
          <Row>
            <Col lg={4} />
            <Col lg={4}>
              <Row>
                <Col lg={4}>
                  <a
                    href="https://www.facebook.com/share/6b1rbDY3qxkddj8Y/?mibextid=qi2Omg"
                    target="_blank"
                  >
                    <img
                      className="social-media-img"
                      src="../fb.png"
                      alt="Facebook"
                    />
                  </a>
                </Col>
                <Col lg={4}>
                  <a href="https://www.tiktok.com/@ultra.tiger" target="_blank">
                    <img
                      className="social-media-img"
                      src="../tt.png"
                      alt="Tik Tok"
                    />
                  </a>
                </Col>
                <Col lg={4}>
                  <a
                    href="https://www.instagram.com/ultra.energy?igsh=MWh5MWpkazRnYmJjaA=="
                    target="_blank"
                  >
                    <img
                      className="social-media-img"
                      src="../ig.png"
                      alt="Instagram"
                    />
                  </a>
                </Col>
              </Row>
            </Col>
            <Col lg={4} />
          </Row>
        </div>
        <RightContent />
      </div>
      <div className="mobile-content">
        <Logo />
        {/* <Navigation /> */}
        <div className="middle-content">
          <Row>
            <Col xs={3} />
            <Col xs={6}>
              <img
                className="home-bogdan-figure-1"
                src="../U0015BogdanovicFigure1v1.png"
                alt="Logo"
              />
            </Col>
            <Col xs={3} />
          </Row>
          {renderText("NAGRADNA", "text-white-big lh-1")}
          {renderText("IGRA JE", "text-white-big lh-1")}
          {renderText("ZAVRŠENA", "text-white-big lh-1")}
          <br />
          <Row>
            <Col xs={3} />
            <Col xs={6} className="ultra-bg p-10">
              {renderText("PRATITE NAS NA", "text-black-small fs-14")}
            </Col>
            <Col xs={3} />
          </Row>
          <br />
          <Row>
            <Col xs={3} />
            <Col xs={6}>
              <Row>
                <Col xs={4}>
                  <a
                    href="https://www.facebook.com/share/6b1rbDY3qxkddj8Y/?mibextid=qi2Omg"
                    target="_blank"
                  >
                    <img
                      className="social-media-img"
                      src="../fb.png"
                      alt="Facebook"
                    />
                  </a>
                </Col>
                <Col xs={4}>
                  <a href="https://www.tiktok.com/@ultra.tiger" target="_blank">
                    <img
                      className="social-media-img"
                      src="../tt.png"
                      alt="Tik Tok"
                    />
                  </a>
                </Col>
                <Col xs={4}>
                  <a
                    href="https://www.instagram.com/ultra.energy?igsh=MWh5MWpkazRnYmJjaA=="
                    target="_blank"
                  >
                    <img
                      className="social-media-img"
                      src="../ig.png"
                      alt="Instagram"
                    />
                  </a>
                </Col>
              </Row>
            </Col>
            <Col xs={3} />
          </Row>
        </div>
        <BottomContent />
      </div>
    </div>
  );
};

export default AfterEnd;
