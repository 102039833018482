import React from "react";
import LeftContent from "../Common/LeftContent";
import RightContent from "../Common/RightContent";
import { Col, Row } from "react-bootstrap";
import Logo from "../Common/Logo";
import Navigation from "../Common/Navigation";
import BottomContent from "../Common/BottomContent";

const Rules: React.FC = () => {
  const renderText = (title: string, className?: string) => {
    return (
      <Row>
        <Col>
          <span className={className}>{title}</span>
        </Col>
      </Row>
    );
  };
  const handleClick = () => {
    window.open("/pravila2.pdf");
  };

  return (
    <div className="rules">
      <div className="desktop-content">
        <LeftContent />
        <div className="middle-content">
          <Row>
            <Col lg={2} />
            <Col lg={8} className="ultra-bg p-30">
              {renderText(
                "Ova Pravila stupaju na snagu danom njihovog donošenja,",
                "text-white-small"
              )}
              {renderText(
                "a prema trećim licima danom javnog objavljivanja u dnevnom listu,",
                "text-white-small"
              )}
              {renderText(
                "a nakon dobijanja saglasnosti Ministra finansija.",
                "text-white-small"
              )}
              <br />
              <div>
                <button onClick={handleClick}>
                  {renderText("Pravila", "text-black-small")}
                </button>
              </div>
            </Col>
            <Col lg={2} />
          </Row>
        </div>
        <RightContent />
      </div>
      <div className="mobile-content">
        <Logo />
        <Navigation />
        <div className="middle-content">
          <Col lg={2} />
          <Col lg={8} className="ultra-bg p-30">
            {renderText(
              "Ova Pravila stupaju na snagu danom njihovog donošenja,",
              "text-white-small"
            )}
            {renderText(
              "a prema trećim licima danom javnog objavljivanja u dnevnom listu,",
              "text-white-small"
            )}
            {renderText(
              "a nakon dobijanja saglasnosti Ministra finansija.",
              "text-white-small"
            )}
            <br />
            <div>
              <button onClick={handleClick}>
                {renderText("Pravila", "text-black-small")}
              </button>
            </div>
          </Col>
          <Col lg={2} />
        </div>
        <BottomContent />
      </div>
    </div>
  );
};

export default Rules;
