import axios, { AxiosRequestConfig } from "axios";

const getWinners = async (date: string): Promise<any> => {
  try {
    // date = "20210930";
    const config: AxiosRequestConfig = {
      method: "GET",
      url: `https://ultrachallenge.rs/api/ultra/winners`,
      headers: {
        "Content-Type": "application/json",
      },
      params: {
        date,
      },
    };

    const response = await axios(config);
    const { data } = response;
    const { code } = data;

    if (code !== 200) {
      return [];
    }

    return data.data;
  } catch (error) {
    return [];
  }
};

export default getWinners;
