import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import BottomContent from "../Common/BottomContent";
import LeftContent from "../Common/LeftContent";
import Logo from "../Common/Logo";
import Navigation from "../Common/Navigation";
import RightContent from "../Common/RightContent";
import getWinners from "../helpers/getWinners";
import moment from "moment";
import DatePicker from "./DatePicker";
import { MDBTable, MDBTableBody, MDBTableHead } from "mdbreact";
import getWinnersWeekly from "../helpers/getWinnersWeekly";
import getWinner from "../helpers/getWinner";

interface winersDto {
  phone: string;
  award: string;
}

const Winners: React.FC = () => {
  const renderText = (title: string, className?: string) => {
    return (
      <Row>
        <Col>
          <span className={className}>{title}</span>
        </Col>
      </Row>
    );
  };

  const [broj, setBroj] = useState(1);
  const prikaziD = () => {
    setBroj(1);
  };

  const prikaziN = () => {
    setBroj(2);
  };

  const prikaziG = () => {
    setBroj(3);
  };

  const [winners, setWinners] = useState<winersDto[]>([]);
  const [message, setMessage] = useState<string>(
    "Nije pronadjen nijedan dobitnik za izabrani datum"
  );
  const [selectedWeek, setSelectedWeek] = useState<number>(1);
  const [date, setDate] = useState<moment.Moment>(moment().subtract(1, "days"));
  const [loader, setLoader] = useState<boolean>(true);
  const { innerWidth: width, innerHeight: height } = window;

  useEffect(() => {
    const fetchWinners = async () => {
      let response;
      const targetDate = new Date("2023-08-26");
      if (broj === 1) {
        response = await getWinners(date.format("YYYYMMDD"));
      } else if (broj === 2) {
        response = await getWinnersWeekly(selectedWeek);
      } else if (broj === 3 && Date.now() > targetDate.getTime()) {
        response = await getWinner();
      } else {
        setWinners([]);

        // response = await getWinners(date.format("YYYYMMDD"));
      }

      if (response) {
        setMessage("success");
        setWinners([...response]);
      }
    };

    fetchWinners();
    // const tmp = async () => {
    //   const response = await getWinners(date.format("YYYYMMDD"));
    //   if (response) {
    //     setMessage("success");
    //     setWinners(response);
    //   }
    // };

    // tmp();
  }, [date, selectedWeek, broj]);
  // za nedelju dropdown sa brojevima od 1 do 6
  useEffect(() => {
    setTimeout(() => {
      setLoader(false);
    }, 1000);
  }, [winners]);

  const weekOptions = Array.from({ length: 6 }, (_, index) => index + 1);

  return (
    <div className="winners">
      <div className="desktop-content">
        <LeftContent />
        <div className="middle-content">
          <button className="awards-button" onClick={prikaziD}>
            DNEVNE NAGRADE
          </button>
          <button className="awards-button" onClick={prikaziN}>
            NEDELJNE NAGRADE
          </button>
          <button className="awards-button" onClick={prikaziG}>
            GLAVNA NAGRADA
          </button>
          {broj === 1 && (
            <div>
              <DatePicker setDate={setDate} />
              <div className="table_page_content">
                {winners.length === 0 ? (
                  loader ? (
                    <div
                      className="spinner-border text-light"
                      role="status"
                      style={{ marginTop: "30vh" }}
                    >
                      <span className="sr-only">Loading...</span>
                    </div>
                  ) : (
                    <h3 className="text-light" style={{ marginTop: "25vh" }}>
                      NE POSTOJE DOBITNICI ZA IZABRANI DATUM!
                    </h3>
                  )
                ) : (
                  <div className="table_div">
                    <MDBTable
                      bordered
                      scrollY
                      maxHeight={
                        width > 700 && height < 700 ? "50vh" : "58.5vh"
                      }
                      className="table"
                    >
                      <MDBTableHead>
                        <tr>
                          <th></th>
                          <th>NAGRADA</th>
                          <th>TELEFON</th>
                        </tr>
                      </MDBTableHead>
                      <MDBTableBody>
                        {message === "success" &&
                          winners &&
                          winners.map((winner, i) => (
                            <tr key={i}>
                              <td>{i + 1}.</td>
                              <td>{winner.award}</td>
                              <td>{winner.phone}</td>
                            </tr>
                          ))}
                      </MDBTableBody>
                    </MDBTable>
                  </div>
                )}
              </div>
            </div>
          )}
          {broj === 2 && (
            <div>
              <div>
                <select
                  className="week-dropdown"
                  value={selectedWeek}
                  onChange={(e) => setSelectedWeek(Number(e.target.value))}
                >
                  {weekOptions.map((week) => (
                    <option key={week} value={week}>
                      {week}. nedelja
                    </option>
                  ))}
                </select>
              </div>
              <div className="table_page_content">
                {winners.length === 0 ? (
                  loader ? (
                    <div
                      className="spinner-border text-light"
                      role="status"
                      style={{ marginTop: "30vh" }}
                    >
                      <span className="sr-only">Loading...</span>
                    </div>
                  ) : (
                    <h3 className="text-light" style={{ marginTop: "25vh" }}>
                      NE POSTOJE DOBITNICI ZA IZABRANI DATUM!
                    </h3>
                  )
                ) : (
                  <div className="table_div">
                    <MDBTable
                      bordered
                      scrollY
                      maxHeight={
                        width > 700 && height < 700 ? "50vh" : "58.5vh"
                      }
                      className="table"
                    >
                      <MDBTableHead>
                        <tr>
                          <th>NAGRADA</th>
                          <th>TELEFON</th>
                        </tr>
                      </MDBTableHead>
                      <MDBTableBody>
                        {message === "success" &&
                          winners &&
                          winners.map((winner, i) => (
                            <tr key={i}>
                              <td>{winner.award}</td>
                              <td>{winner.phone}</td>
                            </tr>
                          ))}
                      </MDBTableBody>
                    </MDBTable>
                  </div>
                )}
              </div>
            </div>
          )}

          {broj === 3 && (
            <div>
              <div className="table_page_content">
                {winners.length === 0 ? (
                  loader ? (
                    <div
                      className="spinner-border text-light"
                      role="status"
                      style={{ marginTop: "30vh" }}
                    >
                      <span className="sr-only">Loading...</span>
                    </div>
                  ) : (
                    <h3 className="text-light" style={{ marginTop: "25vh" }}>
                      NIJE JOŠ IZVUČEN DOBITNIK!
                    </h3>
                  )
                ) : (
                  <div className="table_div">
                    <MDBTable
                      bordered
                      scrollY
                      maxHeight={
                        width > 700 && height < 700 ? "50vh" : "58.5vh"
                      }
                      className="table"
                    >
                      <MDBTableHead>
                        <tr>
                          <th>NAGRADA</th>
                          <th>TELEFON</th>
                        </tr>
                      </MDBTableHead>
                      <MDBTableBody>
                        {message === "success" &&
                          winners &&
                          winners.map((winner, i) => (
                            <tr key={i}>
                              <td>{winner.award}</td>
                              <td>{winner.phone}</td>
                            </tr>
                          ))}
                      </MDBTableBody>
                    </MDBTable>
                  </div>
                )}
              </div>
            </div>
          )}
          {/* <Row>
            <Col lg={2} />
            <Col lg={8} className="white-bg p-30">
              {renderText("SVAKOG DRUGOG DANA", "text-ultra-small fw-700")}
              {renderText(
                "DRES BOGDANA BOGDANOVIĆA",
                "text-ultra-green-small fw-700"
              )}
            </Col>
            <Col lg={2} />
          </Row> */}
        </div>
        <RightContent />
      </div>
      <div className="mobile-content">
        <Logo />
        <Navigation />
        <div className="middle-content">
          <button className="awards-button" onClick={prikaziD}>
            DNEVNE
          </button>
          <button className="awards-button" onClick={prikaziN}>
            NEDELJNE
          </button>
          <button className="awards-button" onClick={prikaziG}>
            GLAVNA
          </button>

          {broj === 1 && (
            <>
              <DatePicker setDate={setDate} />
              <div className="table_page_content">
                {winners.length === 0 ? (
                  loader ? (
                    <div
                      className="spinner-border text-light"
                      role="status"
                      style={{ marginTop: "10vh" }}
                    >
                      <span className="sr-only">Loading...</span>
                    </div>
                  ) : (
                    <h3 className="text-light">
                      NE POSTOJE DOBITNICI ZA IZABRANI DATUM!
                    </h3>
                  )
                ) : (
                  <div className="table_div">
                    <MDBTable
                      bordered
                      scrollY
                      maxHeight={
                        width > 700 && height < 700 ? "50vh" : "58.5vh"
                      }
                      className="table"
                    >
                      <MDBTableHead>
                        <tr>
                          <th>NAGRADA</th>
                          <th>TELEFON</th>
                        </tr>
                      </MDBTableHead>
                      <MDBTableBody>
                        {message === "success" &&
                          winners &&
                          winners.map((winner, i) => (
                            <tr key={i}>
                              <td>{winner.award}</td>
                              <td>{winner.phone}</td>
                            </tr>
                          ))}
                      </MDBTableBody>
                    </MDBTable>
                  </div>
                )}
              </div>
            </>
          )}

          {broj === 2 && (
            <>
              <div>
                <select
                  className="week-dropdown"
                  value={selectedWeek}
                  onChange={(e) => setSelectedWeek(Number(e.target.value))}
                >
                  {weekOptions.map((week) => (
                    <option key={week} value={week}>
                      {week}. nedelja
                    </option>
                  ))}
                </select>
              </div>
              <div className="table_page_content">
                {winners.length === 0 ? (
                  loader ? (
                    <div
                      className="spinner-border text-light"
                      role="status"
                      style={{ marginTop: "10vh" }}
                    >
                      <span className="sr-only">Loading...</span>
                    </div>
                  ) : (
                    <h3 className="text-light">
                      NE POSTOJE DOBITNICI ZA IZABRANI DATUM!
                    </h3>
                  )
                ) : (
                  <div className="table_div">
                    <MDBTable
                      bordered
                      scrollY
                      maxHeight={
                        width > 700 && height < 700 ? "50vh" : "58.5vh"
                      }
                      className="table"
                    >
                      <MDBTableHead>
                        <tr>
                          <th>NAGRADA</th>
                          <th>TELEFON</th>
                        </tr>
                      </MDBTableHead>
                      <MDBTableBody>
                        {message === "success" &&
                          winners &&
                          winners.map((winner, i) => (
                            <tr key={i}>
                              <td>{winner.award}</td>
                              <td>{winner.phone}</td>
                            </tr>
                          ))}
                      </MDBTableBody>
                    </MDBTable>
                  </div>
                )}
              </div>
            </>
          )}

          {broj === 3 && (
            <>
              <div className="table_page_content">
                {winners.length === 0 ? (
                  loader ? (
                    <div
                      className="spinner-border text-light"
                      role="status"
                      style={{ marginTop: "10vh" }}
                    >
                      <span className="sr-only">Loading...</span>
                    </div>
                  ) : (
                    <h3 className="text-light">
                      NE POSTOJE DOBITNICI ZA IZABRANI DATUM!
                    </h3>
                  )
                ) : (
                  <div className="table_div">
                    <MDBTable
                      bordered
                      scrollY
                      maxHeight={
                        width > 700 && height < 700 ? "50vh" : "58.5vh"
                      }
                      className="table"
                    >
                      <MDBTableHead>
                        <tr>
                          <th>NAGRADA</th>
                          <th>TELEFON</th>
                        </tr>
                      </MDBTableHead>
                      <MDBTableBody>
                        {message === "success" &&
                          winners &&
                          winners.map((winner, i) => (
                            <tr key={i}>
                              <td>{winner.award}</td>
                              <td>{winner.phone}</td>
                            </tr>
                          ))}
                      </MDBTableBody>
                    </MDBTable>
                  </div>
                )}
              </div>
            </>
          )}
        </div>
        <BottomContent />
      </div>
    </div>
  );
};

export default Winners;
