import React from "react";
import { Button, Row } from 'react-bootstrap';
import { Link, useLocation } from "react-router-dom";

const NavButtons: React.FC = () => {
    const location = useLocation();

    const renderNavButton = (to: string, title: string) => {
        return (
            <Row>
                <Link to={to}>
                    <Button className={(to === location.pathname) || (to === "/kontakt" && location.pathname === "/kraj" ) ? "custom-button custom-button-active" : "custom-button"}>
                        {title}
                    </Button>
                </Link>
            </Row>
        );
    }

    return (
        <div className="nav-buttons">
            {renderNavButton("/prijava", "PRIJAVI SE")}
            {renderNavButton("/mehanizam", "MEHANIZAM")}
            {renderNavButton("/nagrade", "NAGRADE")}
            {renderNavButton("/dobitnici", "DOBITNICI")}
            {renderNavButton("/pravila", "PRAVILA")}
            {renderNavButton("/kontakt", "KONTAKT")}
        </div>
    );
}

export default NavButtons;