import { Row } from "react-bootstrap";
import { NavLink } from "react-router-dom";

const Logo = () => {
  return (
    <Row>
      <NavLink to="/">
        <img className="logo" src="../logo.png" alt="Ultra" />
      </NavLink>
    </Row>
  );
};

export default Logo;
