import { Row } from "react-bootstrap";

const RightContent = (props: any) => {
  if (props.customRightContent === "awards") {
    return (
      <div className="right-content-awards">
        <Row className="image-space" />
      </div>
    );
  }

  return (
    <div className="right-content">
      <Row className="image-space" />
    </div>
  );

  return (
    <div className="right-content">
      <Row className="image-space" />
      <Row className="image-space" />
      <Row className="image-space" />
    </div>
  );
  
}

export default RightContent;