import React from "react";
import { Col, Row } from "react-bootstrap";
import LeftContent from "../Common/LeftContent";
import RightContent from "../Common/RightContent";
import Navigation from "../Common/Navigation";
import BottomContent from "../Common/BottomContent";

const BeforeStart: React.FC = () => {
  const renderText = (title: string, className?: string) => {
    return (
      <Row>
        <Col>
          <span className={className}>{title}</span>
        </Col>
      </Row>
    );
  };

  return (
    <div className="beforeStart">
      <div className="desktop-content">
        {/* <LeftContent /> */}
        <div className="middle-content">
          {renderText("BOGI", "text-white-big")}
          {renderText("TE ZOVE U", "text-ultra-green-big lh-0")}
          {renderText("AMERIKU", "text-white-big lh-1")}
          <br />
          {renderText(
            "PRIJAVE ZA NAGRADNU IGRU",
            "text-white-small lh-0 fw-500"
          )}
          {renderText(
            "TRAJU od 05.07. do 16.08. 2024. GODINE",
            "text-white-small lh-0 fw-500"
          )}
          <br />
        </div>
        <RightContent />
      </div>
      <div className="mobile-content">
        <Navigation />
        <div className="middle-content">
          {renderText("BOGI", "text-white-big fs-55")}
          {renderText("TE ZOVE U", "text-ultra-green-big fs-55 lh-03")}
          {renderText("AMERIKU", "text-white-big fs-55 lh-1")}
          <br />
          {renderText(
            "PRIJAVE ZA NAGRADNU IGRU",
            "text-white-small fs-14 lh-0 fw-500"
          )}
          {renderText(
            "TRAJU od 05.07. do 16.08. 2024. GODINE",
            "text-white-small fs-14 lh-1 fw-500"
          )}
          <br />
        </div>
        <Row>
          <Col xs={2} />
          <Col xs={8}>
            <img
              className="home-bogdan-figure-1"
              src="../U0015BogdanovicFigure1v1.png"
              alt="Logo"
            />
          </Col>
          <Col xs={2} />
        </Row>
      </div>
    </div>
  );
};

export default BeforeStart;
